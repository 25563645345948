var _paq = window._paq = window._paq || [];
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(["setDomains", ["*.gemeinsam-gegen-armut.org","*.ggap-ev.org"]]);
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
  var u="https://matomo.bovender.de/";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '2']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();

// From https://github.com/turbolinks/turbolinks/issues/436#issuecomment-456862864
// Send Matomo a new event when navigating to a new page using Turbolinks
// (see https://developer.matomo.org/guides/spa-tracking)
(function() {
  var previousPageUrl = null;
  addEventListener('turbolinks:load', function(event) {
    if (previousPageUrl) {
      var _paq = window._paq = window._paq || [];
      _paq.push(['setReferrerUrl', previousPageUrl]);
      _paq.push(['setCustomUrl', window.location.href]);
      _paq.push(['setDocumentTitle', document.title]);
      if (event.data && event.data.timing) {
        _paq.push(['setGenerationTimeMs', event.data.timing.visitEnd - event.data.timing.visitStart]);
      }
      _paq.push(['trackPageView']);
    }
    previousPageUrl = window.location.href;
  });
})();
